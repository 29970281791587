import './import-jquery'
import 'babel-polyfill'

import "jquery-ui-dist/jquery-ui.js"
import('imagesloaded')
import 'magnific-popup'

window.Drupal = {}

/**
 * =====================================================================================================================
 * Weber Base
 * =====================================================================================================================
 */

const Theme = window.Theme = (function (window, $) {

    // THEME: Settings
    // =================================================================================================================
    let smoothScoll;
    window.wpjQuery = $;


    const breakpoints = {
        xxs: 400,
        xs: 480,
        sm: 768,
        md: 992,
        lg: 1200
    };

    // THEME: Core
    // =================================================================================================================

    // Drupal Translation
    // -----------------------------------------------------------------------------------------------------------------
    const Drupal = typeof window.Drupal !== 'undefined' && window.Drupal || {
        t: function (value) {
            return value;
        }
    };


    // Slideshows
    // -----------------------------------------------------------------------------------------------------------------

    const slideshowFeaturedProducts = function () {
        let $el = $('.slideshow-featured-products');

        $el.each(function () {
            let $this = $(this);
            let options = $this.data();
            let $slide = $this.find('.slideshow-inner');
            let $buttons = $this.find('.slideshow-nav > button');

                let defaults = {
                    rows: 0,
                    infinite: false,
                    autoplay: false,
                    slidesToShow: 3,
                    prevArrow: $buttons.eq(0),
                    nextArrow: $buttons.eq(1),
                    arrows: true,
                    dots: true,
                    adaptiveHeight: true,
                    responsive: [
                        {
                            breakpoint: breakpoints.md,
                            settings: {
                                slidesToShow: 3,
                                arrows: false,
                                dots: true
                            }
                        }, {
                            breakpoint: breakpoints.sm,
                            settings: {
                                slidesToShow: 1,
                                arrows: false,
                                dots: true
                            }
                        }, {
                            breakpoint: breakpoints.xxs,
                            settings: {
                                slidesToShow: 1,
                                arrows: false,
                                dots: true
                            }
                        }
                    ]
                };

            let settings = $.extend({}, defaults, options);

            $slide.slick(settings);
        });
    };

    const slideshowVideos = function () {
        let $el = $('.slideshow-videos');

        $el.each(function () {
            let $this = $(this);
            let options = $this.data();
            let $slide = $this.find('.slideshow-inner');
            let $buttons = $this.find('.slideshow-nav > button');

            let defaults = {
                rows: 0,
                infinite: false,
                autoplay: false,
                slidesToShow: 3,
                prevArrow: $buttons.eq(0),
                nextArrow: $buttons.eq(1),
                arrows: true,
                dots: true,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: breakpoints.md,
                        settings: {
                            slidesToShow: 3,
                            arrows: false,
                            dots: true
                        }
                    }, {
                        breakpoint: breakpoints.sm,
                        settings: {
                            slidesToShow: 2,
                            arrows: false,
                            dots: true
                        }
                    }, {
                        breakpoint: breakpoints.xs,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                            dots: true
                        }
                    }
                ]
            };

            let settings = $.extend({}, defaults, options);

            $slide.slick(settings);
        });
    };

    function cloneAttributes(target, source) {
        [...source.attributes].forEach( attr => { target.setAttribute(attr.nodeName ,attr.nodeValue) })
    }

    const slideshowAdvantages = function () {
        let $el = $('.slideshow-advantages');

        $el.each(function () {
            let $this = $(this);
            let options = $this.data();
            let $slide = $this.find('.slideshow-inner');
            let $buttons = $this.find('.slideshow-nav > button');

            let defaults = {
                rows: 0,
                infinite: false,
                autoplay: false,
                slidesToShow: 4,
                prevArrow: $buttons.eq(0),
                nextArrow: $buttons.eq(1),
                arrows: true,
                dots: true,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: breakpoints.md,
                        settings: {
                            slidesToShow: 4,
                            arrows: false,
                            dots: true
                        }
                    }, {
                        breakpoint: breakpoints.sm,
                        settings: {
                            slidesToShow: 3,
                            arrows: false,
                            dots: true
                        }
                    }, {
                        breakpoint: breakpoints.xs,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                            dots: true
                        }
                    }
                ]
            };

            let settings = $.extend({}, defaults, options);

            $slide.slick(settings);

            $this.find('div[href]').each(function (i, el) {
                const newLink = el.parentNode.appendChild(document.createElement('a'))
                cloneAttributes(newLink, el)
                newLink.append(...el.childNodes);
                el.parentNode.removeChild(el)
            })
        });
    };
    const slideshowPartners = function () {
        let $el = $('.slideshow-partners');

        $el.each(function () {
            let $this = $(this);
            let options = $this.data();
            let $slide = $this.find('.slideshow-inner');
            let $buttons = $this.find('.slideshow-nav > button');

            let defaults = {
                rows: 0,
                infinite: false,
                autoplay: false,
                slidesToShow: 4,
                slidesToScroll: 3,
                prevArrow: $buttons.eq(0),
                nextArrow: $buttons.eq(1),
                arrows: true,
                dots: true,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: breakpoints.md,
                        settings: {
                            slidesToShow: 4,
                            arrows: false,
                            dots: true
                        }
                    }, {
                        breakpoint: breakpoints.sm,
                        settings: {
                            slidesToShow: 2,
                            arrows: false,
                            dots: true
                        }
                    }, {
                        breakpoint: breakpoints.xs,
                        settings: {
                            slidesToShow: 2,
                            arrows: false,
                            dots: true
                        }
                    }
                ]
            };

            let settings = $.extend({}, defaults, options);

            $slide.slick(settings);

            $this.find('div[href]').each(function (i, el) {
                const newLink = el.parentNode.appendChild(document.createElement('a'))
                cloneAttributes(newLink, el)
                newLink.append(...el.childNodes);
                el.parentNode.removeChild(el)
            })
        });
    };

    const runProgressBar = function ($slick, $bar) {
        var time = 10;
        var $innerBar = $bar.find('.slider-progress-inner')
        var isPause,
            tick,
            percentTime;
        function interval() {
            if(isPause === false) {
                percentTime += 1 / (time+0.1);
                $innerBar.css({
                    width: percentTime+"%"
                });
                if(percentTime >= 100)
                {
                    $slick.slick('slickNext');
                    startProgressbar();
                }
            }
        }

        function startProgressbar() {
            resetProgressbar();
            percentTime = 0;
            isPause = false;
            tick = setInterval(interval, 10);
        }

        function resetProgressbar() {
            $innerBar.css({
                width: 0+'%'
            });
            clearTimeout(tick);
        }

        startProgressbar();

        return startProgressbar
    }

    const slideshowOverlay = function ($slide, $caption) {
        let info = $caption.find('.slideshow-comfort-info');

        const newArr = [
            [info[0], info[3]],
            [info[1]],
            [info[2], info[5]],
            [info[4]],
        ]

        const showSlide = (currentSlide) => {
            newArr.forEach((arr, i) => {
                arr.forEach(el => {
                    let transitions = $(el).find('.slideshow-transition');
                    transitions.removeClass('active')
                    if(transitions[currentSlide]) {
                        setTimeout(() => transitions[currentSlide].classList.add('active'), Math.min(i, 0) * 250)
                    } else {
                        // console.log(el, currentSlide)
                    }
                })
            })
        }
        showSlide(0);
        $slide.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            showSlide(nextSlide)
        });
    }

    const slideshowWide = function () {
        let $el = $('.slideshow-wide');

        $el.each(function () {
            let $this = $(this);
            let options = $this.data();
            let $slide = $this.find('.slideshow-inner');
            let $buttons = $this.find('.slideshow-nav > button');
            let $bar = $this.find('.slider-progress');
            let $caption = $this.parent().find('.slideshow-caption');
            let $toggle = $this.parent().find('.slideshow-comfort-button');
            let $toggle2 = $this.parent().parent().find('.slideshow-rotate-button');
            let toggleFn = () => void $slide.slick('slickNext')

            let defaults = {
                rows: 0,
                autoplay: true,
                autoplaySpeed: 25000,
                slidesToShow: 1,
                prevArrow: $buttons.eq(0),
                nextArrow: $buttons.eq(1),
                fade: true,
                dots: true,
                cssEase: 'linear'
            };

            let settings = $.extend({}, defaults, options);

            $slide.slick(settings);
            if($bar) {
                const startBar = runProgressBar($slide, $bar);
                toggleFn = () => {
                    $slide.slick('slickNext')
                    startBar()
                }
            }
            if($caption) {
                slideshowOverlay($slide, $caption);
            }
            if($toggle) {
                $toggle.on('click', toggleFn)
            }
            if($toggle2) {
                $toggle2.on('click', toggleFn)

                $slide.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                    $toggle2.removeClass('a')
                    $toggle2.removeClass('b')
                    $toggle2.addClass(nextSlide === 1 ? 'b' : 'a')
                });
            }
        });
    };

    // Smooth Scroll
    // -----------------------------------------------------------------------------------------------------------------
    smoothScoll = function () {
        $(document).on('click', '[data-scroll]', function (event) {
            let target = this;

            event.preventDefault();

            $.smoothScroll({
                scrollTarget: target.hash,
                offset: -getNavbarHeight(30)
            });
        });
    };

    // Modal Video
    // -----------------------------------------------------------------------------------------------------------------
    const modalVideo = function () {
        let $element = $('[data-toggle="modal-video"]');

        // fixme
        $element.magnificPopup({
            type: 'iframe',
            iframe: {
                markup:
                    '<div class="modal-dialog modal-lg modal-video">' +
                    '   <div class="mfp-close"></div>' +
                    '   <div class="modal-content">' +
                    '       <div class="modal-body">' +
                    '           <div class="embed-responsive embed-responsive-16by9">' +
                    '               <iframe class="embed-responsive-item mfp-iframe" allowfullscreen></iframe>' +
                    '           </div>' +
                    '       </div>' +
                    '   </div>' +
                    '</div>'
            }
        });
    };

    const lazyloadHotfix = function () {
        for(let el of document.querySelectorAll('[data-bg]')) {
            el.style['background-image'] = `url(${el.dataset.bg})`
        }
    }

    const closeMenu = function () {
        $('.navbar-toggle').each(function() {
            if(this.classList.contains('is-active')) {
                this.click()
            }
        })
    }

    const smoothAnchors = function () {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                const target = document.querySelector(anchor.getAttribute('href'));
                if(!target) return;

                target.scrollIntoView({
                    behavior: 'smooth'
                });
                closeMenu();
            });
        });
    }

    const calculator = function () {
        const $form = $('#consumption-calculator-form')

        $form.each(() => {
            const ui = {
                surface: $('#edit-surface'),
                width: $('#edit-width'),
                submit: $('#edit-submit'),

                results: $('.table-result'),
                bags: $('#calc-bags-value'),
            }

            ui.results.hide()

            ui.submit.click((ev) => {
                ev.preventDefault();

                // get values, but dont convert to SI yet due to FP rounding errors
                const s = parseInt(ui.surface[0].value)
                const w = parseInt(ui.width[0].value)

                const res = Math.ceil(s/w)

                ui.bags.text(res)
                ui.results.show()
            })
        })
    }

    const modalConnector = function () {
        const modals = [
            $('#fsModal1'),
            $('#fsModal2'),
            $('#fsModal3'),
        ]
        const links = [
            '/product-1.html',
            '/product-2.html',
            '/product-3.html',
        ]
        const buttons = document.querySelectorAll('[data-iframe-target]');
        for(const button of buttons) {
            const number = parseInt(/\d+/g.exec(button.dataset['iframeTarget'])[0])-1
            const modal = modals[number]
            button.addEventListener('click', () => {
                const iframe = modal.find('iframe');
                if(!iframe.attr('src')) {
                    iframe[0].onload = () => {
                        iframe[0].contentWindow.addEventListener("message", (event) => {
                            if(event.data === 'store-locate') {
                                modals.forEach(modal => modal.modal('hide'))
                                const target = document.querySelector('#stores');
                                if(!target) return;

                                target.scrollIntoView({
                                    behavior: 'smooth'
                                });
                            }
                            if(event.data === 'calculate') {
                                $('#calcModal').modal('show')
                            }
                        })
                    };
                    iframe.attr('src', links[number]);
                }
                modal.modal('show')
            })
        }

        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            const url = anchor.getAttribute('href')
            const post = (data) => {
                anchor.addEventListener('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();

                    window.postMessage(data)
                });
            }

            if(url === '#store-locator') {
                post('store-locate')
            }

            if(url === '#consumption-calculator') {
                post('calculate')
            }
        });
    }

    function initDeferImages() {
        Array.prototype.forEach.call(document.querySelectorAll('[data-src]'), element => {
            element.setAttribute('src', element.getAttribute('data-src'));
        })
        Array.prototype.forEach.call(document.querySelectorAll('[data-srcset]'), element => {
            element.setAttribute('srcset', element.getAttribute('data-srcset'));
        })
    }

    // Theme: Return object
    // =================================================================================================================
    return {
        init: function () {
            lazyloadHotfix();
            slideshowFeaturedProducts();
            slideshowVideos();
            slideshowAdvantages();
            slideshowPartners();
            slideshowWide();
            modalVideo();
            smoothScoll();
            smoothAnchors();
            modalConnector();
            calculator();
            initDeferImages();
        },
    };

})(window, jQuery);


import('./algolia').then(async () => {
    await import('slick-carousel')
    await import('bootstrap-sass')
    $(document).ready(function() {
        Theme.init()
        // imagesLoaded.makeJQueryPlugin($)


        const map = document.getElementById('store-map')
        if(map) {
            Drupal.loadMap();
        }
        const $tabs = $('#tabs');
        $tabs.tabs();
    })
})


